import React from 'react'
import video1 from '../Assets/video1.mp4';
import'../Styles.css'


function WatchVideo() {
    // const playerRef = useRef(null);
  return (
    <div>

      <body>
      <section class="watch-video">

<div class="video-container">
   <div class="video">
      <video src={video1} controls poster="images/post-1-1.png" id="video"></video>
      {/* <ReactPlayer ref={playerRef} url={video1} controls={true} width="100%"/> */}
   </div>
   <h3 class="title">complete HTML tutorial (part 01)</h3>

</div>

</section>
<footer class="footer">

&copy; copyright @ 2024 by <span>ManthaTech Solutions</span> | all rights reserved!

</footer>
</body>
    </div>
  )
}

export default WatchVideo
