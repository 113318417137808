import React from "react";
import Navbar1 from "../Navbar/Navbar1";


const Front = () => {
  return (
    <div>
      <Navbar1 />
      {/* <LoginSignup/> */}

    </div>
  );
};

export default Front;
