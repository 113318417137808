import React from 'react';
import './Navbar.css';

const Navbar = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
  //
  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  return (
    <div className='nav'>
      <div className="nav-logo">Learning Management System</div>

    </div>


  );


}

export default Navbar;
